@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap");

body {
	margin: 0;
	font-family: "Open Sans", "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.select-rounded-md {
	border-radius: var(--chakra-radii-md);
}

.scrollbar-none::-webkit-scrollbar {
	width: 0px;
}
