@media screen and (min-width: 768px) {
	#scrollableDiv {
		padding-right: 5px;
	}
	#scrollableDiv::-webkit-scrollbar {
		width: 5px;
	}
	#scrollableDiv::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
	#scrollableDiv::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
	}
}
